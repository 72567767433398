/**
 * Retrieves the value of a CSS variable/custom property.
 *
 * @param {string} name - name of the CSS variable (with or without the leading `--`)
 * @returns {string} value of the CSS variable
 */
export function getCSSVariable(name) {
	if (!name.startsWith('--')) name = `--${name}`
	return getComputedStyle(document.documentElement).getPropertyValue(name)
}
