'use strict'
/**
 * This file contains user-facing alerts, usually shown with sweet alert (ONLY for errors/warnings thrown by the easyVerein-Api)
 */

import { alertDanger } from '../globals/alerts'
import { gettext } from '../utils/translation'

const SUCCESS_CODES = new Set([0, 200, 201, 202, 204])

/**
 * Shows a errorMessage from the easyVerein-Api as sweetalert if `status` is not in `SUCCESS_CODES`.
 * !!! Do not use this in case of internal server error which is not thrown by the easyVerein-Api (in that case use internalError) !!!
 *
 * @param {string|number} status - the status code of the response
 * @param {object|Array|string} errorData - the data the response returned, may contain error messages
 * @param {string} separator - separator between multiple error messages, defaults to `"<br>"`
 */
export async function apiError(status, errorData, separator = '<br>') {
	if (SUCCESS_CODES.has(status)) return

	let message = ''

	if (status !== 500) {
		if (typeof errorData === 'string') {
			message = errorData
		} else {
			let csrfTokenMissing = false
			for (const indexOrKey of Object.keys(errorData)) {
				const value = errorData[indexOrKey]
				if (!value) continue
				if (value.includes('CSRF Failed')) {
					csrfTokenMissing = true
				}
				message += Array.isArray(errorData)
					? `${value}${separator}`
					: `${indexOrKey}: ${value}${separator}`
			}
			if (csrfTokenMissing) {
				message = `${gettext(
					'Deine Sitzung ist abgelaufen. <br> Bitte lade die Seite neu.'
				)}<br>${message}`
			}
		}
		alertDanger(undefined, message, {
			messageData: '',
			locationIdentifier: window.location.href,
		})
	} else {
		alertDanger('APx500xInternalError', undefined, {
			messageData: '',
			locationIdentifier: window.location.href,
		})
	}
}
