/**
 * Usage. Get Token to localStorage with TTL
 *
 * @param {string} key the storage key
 * @param {string} value the value
 * @param {number} ttl the time to live in milliseconds
 */
export function setWithExpiry(key, value, ttl) {
	const now = new Date()
	const item = {
		value: value,
		expiry: now.getTime() + parseInt(ttl),
	}
	localStorage.setItem(key, JSON.stringify(item))
}

/**
 * Usage: Get Token from localStorage
 *
 * @param {string} key - the storage key
 * @param {string?} [defaultValue] - the stored value
 * @returns {string} stored value or `defaulValue`
 */
export function getWithExpiry(key, defaultValue = null) {
	const itemStr = localStorage.getItem(key)
	if (!itemStr) {
		return defaultValue
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key)
		return defaultValue
	}
	return item.value
}

/**
 * Saves stringified value under key to session storage
 *
 * @param {string} key - storage key
 * @param {string|number|object} value - value to store
 */
export function setToSession(key, value) {
	sessionStorage.setItem(key, JSON.stringify(value))
}

/**
 * Loads and parses the stringified value stored under key from session storage
 *
 * @param {string} key - storage key
 * @returns {string|number|object} parsed json data
 */
export function getFromSession(key) {
	try {
		return JSON.parse(sessionStorage.getItem(key))
	} catch {
		return sessionStorage.getItem(key)
	}
}

/**
 * Removes the item stored under key from session storage
 *
 * @param {string} key - storage key
 */
export function clearFromSession(key) {
	sessionStorage.removeItem(key)
}
