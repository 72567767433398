import { alertDanger, alertSuccess } from '../../globals/alerts'

/**
 * Copy the value of the targeted DOM element set in the data-clipboard-target of the element triggering this function
 *
 * @param {Event} event - onclick event to copy something into the clipboard
 * @param {Function} callback - (optional) Callback function that is called after success
 * @param {string} attributeContainingText - (optional) The attribute containing the text of the target element to copy
 */
export function copyToClipboard(
	event,
	callback,
	attributeContainingText = 'value'
) {
	const element = event.currentTarget
	const targetSelector = element.dataset?.clipboardTarget
	const target = targetSelector
		? document.querySelector(targetSelector) || element
		: element

	if (target) {
		if (target[attributeContainingText]) {
			navigator.clipboard
				.writeText(target[attributeContainingText])
				.then(copySuccess(callback))
		} else {
			alertDanger('CIx500xCopiedToClipBoard')
		}
	} else {
		alertDanger('CIx500xCopiedToClipBoard')
	}
}

/**
 * show succesMessage for clipboard Copy
 *
 * @param {Function} callback - a callback function
 */
function copySuccess(callback) {
	alertSuccess('CIx000xCopiedToClipBoard').then(() => {
		if (callback) {
			callback()
		}
	})
}
