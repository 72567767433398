/**
 * Clear the server cache for the admin
 *
 * @param {boolean} [reloadWindow] - determines if the page reloads after the cache has been cleared [default=false]
 */
export async function clearCacheForAdmins(reloadWindow = false) {
	const result = await fetch('/app/api/clearcacheforadmins')

	if (result.status === 200 && reloadWindow) {
		window.location.reload(true)
	}
}
