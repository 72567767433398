'use strict'
import { getCSRF, getContextData } from '../django.js'
/**
 * This file contains helper functions regarding the API wrappers.
 * Feel free to expand it, if you need something.
 */
import { apiError } from '../../api/alerts.js'
import { gettext } from '../translation.js'
import { alertChoice } from '../../globals/alerts.js'
import { post } from '../../api/crud.js'
import { successMessage } from '../../globals/messages.js'

/**
 * Gives correct headers for API requests
 *
 * @returns {object} request header object
 * @throws if no CSRF-Token has been included in the HTMl template
 */
export function headers() {
	const csrf = getCSRF()
	return {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
		'X-CSRFToken': csrf,
	}
}

/**
 * Generates a query parameter string from an object of filters
 *
 * @param {object} filter - key-value pairs of the filters
 * @returns {string} string of filters in query parameter form
 */
export function mapFilter(filter) {
	let queryParameter = ''
	for (const key in filter) {
		if (Object.prototype.hasOwnProperty.call(filter, key)) {
			const value = filter[key]
			queryParameter += `&${key}=${encodeURIComponent(value)}`
		}
	}
	return queryParameter
}

/**
 * Checks if condition is true and throws an error.
 * Used in the API wrapper functions to enforce arguments are given.
 *
 * @param {boolean} condition - the condition to check, can be complex
 * @param {string} message - error message
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 */
export function checkCondition(condition, message, showError = true) {
	if (condition) {
		const error = new Error(message || 'Parameter check failed.')
		if (showError) {
			apiError(500, {})
		}
		throw error
	}
}

/**
 * Helper to check if an object is either `undefined`, `null`, or just empty (`{}`)
 *
 * @param {object} object - object to check
 * @returns {boolean} true if object is undefined`, `null`, or just empty (`{}`)
 */
export function isEmptyOrUndefined(object) {
	return (
		object === undefined ||
		object === null ||
		(Object.keys(object).length === 0 && object.constructor === Object) // Checks if object is empty ({})
	)
}

/**
 * Calls and awaits `response.json()`.
 * Returns null if an error occurs while parsing the json
 *
 * @param {object} response - fetch response object
 * @returns {Promise<object>} parsed object or null. Empty object if status code is "204 - No Content"
 */
async function parseResponse(response) {
	if (response.status === 204) {
		return {}
	}
	try {
		return await response.json()
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 * Error handling wrapper for `JSON.stringify`
 *
 * @param {object} data - data to stringify
 * @returns {string|null} string of the object or null
 */
export function stringifyData(data) {
	try {
		return JSON.stringify(data)
	} catch (error) {
		console.error(error)
		return null
	}
}

/**
 * Wrapper to parse a response, show errors if needed and return the data
 *
 * @param {object} response - fetch response object
 * @param {boolean} showError - if true show `api_error` on errors, defaults to `true`
 * @returns {Promise<object>} promise of an object with the status of the response and the data as an object
 */
export async function handleResponse(response, showError = true) {
	const data = await parseResponse(response, showError)
	if (showError) {
		apiError(response.status, data)
	}
	return {
		status: response.status,
		data: data,
	}
}

const abortControllers = {}

/**
 * Creates an AbortController
 *
 * @param {string} id - id for an AbortController
 * @returns {AbortController | undefined} the AbortController if it exists or was created
 */
export const addAbortController = (id) => {
	if (id) {
		const oldController = abortControllers[id]
		if (oldController && !oldController.signal.aborted) {
			oldController.abort()
		}
		abortControllers[id] = new AbortController()
	}
	return abortControllers[id]
}

/**
 * Triggers the abort signal on an AbortController
 *
 * @param {string} id - id for an AbortController
 * @returns {boolean} true if the signal was triggered, else false
 */
export const abortAbortController = (id) => {
	if (abortControllers[id]) {
		abortControllers[id].abort()
		return true
	}
	return false
}

/**
 * Removes an AbortController
 *
 * @param {string} id - id for an AbortController
 * @returns {boolean} true if the controller was removed, else false
 */
export const removeAbortController = (id) => {
	if (abortControllers[id]) {
		delete abortControllers[id]
		return true
	}
	return false
}

/**
 * Asking the user if he wants to trigger push notifications for a specific action and triggering it on confirm (only if the settings allow this)
 *
 * @param {string} action - The push notification action to ask the user for
 * @param {string} endpoint - The normal endpoint of the api to send the push notification to
 * @param {string|number|Array} ids - The ids of the entries to trigger the push notifications for or just one id
 * @param {undefined|string} [title=undefined] - The title containing the question to ask the user if he wants to trigger push notifications
 * @returns {boolean} - A boolean indicating if the notification message was send or not
 */
export async function checkNotificationAction(
	action,
	endpoint,
	ids,
	title = undefined
) {
	const orgSettings = getContextData('orgSettings')
	if (!orgSettings || (orgSettings && !orgSettings[action].value)) {
		// No settings or the settings disallow the notifications for this notification action
		return false
	}
	if (!Array.isArray(ids)) {
		ids = [ids]
	}
	if (title === undefined) {
		title = gettext(
			'Soll die letzte Änderung per App-Benachrichtigung versendet werden?'
		)
	}
	const choice = await alertChoice(undefined, title, {
		isLarge: false,
		confirmButtonText: gettext('Benachrichtigungen senden'),
		cancelButtonText: gettext('Nicht benachrichtigen'),
	})
	if (choice.isConfirmed) {
		const response = await post(`${endpoint}/notification`, { action, ids })
		if (response.status === 204) {
			successMessage({
				msg: gettext('App-Benachrichtigungen erfolgreich versandt'),
				success: true,
			})
			return true
		}
	}
	return false
}
