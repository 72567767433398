/**
 * Returns global Django template constants.
 *
 * @returns {object} object of template constants
 */
export function getGlobals() {
	return {
		languageCode: getContextData('language_code'),
		userID: getContextData('user_id'),
		isDebug: getContextData('is_debug'),
		frontChatId: getContextData('frontChatId'),
		frontSalesChatId: getContextData('frontSalesChatId'),
		frontIdentity: getContextData('frontIdentity'),
	}
}

/**
 * Retrieve and parse data from a Django json_script template tag
 *
 * @param {string} id - name of the json_script (`json_script:"id"`)
 * @returns {Array | object} parsed JSON object, or empty array
 */
export function getContextData(id) {
	if (!id) {
		return []
	}
	const node = document.querySelector(`#${id}`)
	if (node !== undefined) {
		try {
			return JSON.parse(node.innerText)
		} catch (error) {
			return []
		}
	}
	return []
}

/**
 * Returns the CSRF token
 *
 * @throws If no CSRF Token (`{% csrf_token %}`) is on the page
 * @returns {string} CSRF middleware token
 */
export function getCSRF() {
	const csrfInput = document.querySelector('[name=csrfmiddlewaretoken]')
	if (csrfInput === undefined) {
		throw new Error(
			"Couldn't find a CSRF-Token input on the page. Please fix the markup."
		)
	}
	return csrfInput.value
}

/**
 * Converts a Django timestamp to the correct Javascript timestamp.
 * JS timestamps need to be multiplied by 1000.
 *
 * @param {number} djangoTimestamp - timestamp from the backend
 * @returns {number} javascript timestamp
 */
export function toJsTimestamp(djangoTimestamp) {
	return djangoTimestamp * 1000
}

window.getContextData = getContextData // Only used for debugging
