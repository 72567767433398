import { gettext } from '../utils/translation'
import {
	alertSuccess,
	alertDanger,
	alertSuccessConfirmation,
} from '../globals/alerts'
import { toggleVisibility } from '../utils/helpers/show'
import { onclickElements } from '../utils/helpers/eventHelpers'
import { copyToClipboard } from '../utils/helpers/clipboardHelper'

/**
 * Initialize error messages eventlisteners
 *
 * @param {boolean} reduceLayout - use reduced layout (usually message inside of alert)
 */
export function initErrorMessageEventListeners(reduceLayout = false) {
	onclickElements({
		'.err-ex-call': (event) =>
			triggerExtendedErrorMessage(event.currentTarget, reduceLayout),
		'.err-ex-email': (event) => addErrorDetailsInMail(event.currentTarget),
		'.clipboard-btn-alert': (event) =>
			copyToClipboard(event, undefined, 'innerText'),
	})
}

/**
 * Shows browser information and parsed user agent in error message, if neccessary data is provided in the DOM
 *
 * @param {object} element - DOM Element
 * @param {boolean} reduceLayout - use reduced layout (usually message inside of alert)
 */
export function triggerExtendedErrorMessage(element, reduceLayout) {
	const targetId = element.id.split('_')[0]
	const target = document.querySelector(`#${targetId}`)
	if (target.attributes.data) {
		const dataString = target.attributes.data.value.split(',')
		getExtendedErrorMessage(
			dataString[0],
			dataString[1],
			dataString[2],
			encodeURIComponent(dataString[3]),
			targetId,
			reduceLayout
		)
	}
}

/**
 * Shows success or an error message depending on the state of `data`.
 *
 * @param {object} data - the dict containing the response data
 * @param {Function} errorCallback - function on errorCallback
 * @param {Function} successCallback - function on successCallback
 */
export function successMessage(data, errorCallback, successCallback) {
	if (data.success === false) {
		alertDanger(undefined, data.msg).then(function () {
			if (errorCallback !== undefined) {
				errorCallback(data)
			}
		})
	} else {
		let html = data.msg

		if (data.warning) {
			html += `<br><br><div class='alert alert-warning-light'>${data.warning}</div>`
			alertSuccessConfirmation(undefined, html, {
				showCancelButton: data.showCancelButton,
				confirmButtonText: data.confirmButtonText,
			}).then((callbackinfo) => {
				if (successCallback !== undefined) {
					successCallback(data, callbackinfo)
				}
			})
		} else {
			alertSuccess(undefined, html).then(function (data) {
				if (successCallback !== undefined) {
					successCallback(data)
				}
			})
		}
	}
}

/**
 * Show errorMessage for internal server errors by using function successMesage with "success: false"
 * !!! Do not use this in error case of easyVerein-Api (in that case use apiError) !!!
 *
 * @param {object} data the errorData (JSON) from failed request or empty json in case of try-catch logic etc
 */
export async function internalError(data) {
	const response = await getMessageText(
		'APx500xInternalError',
		'',
		window.location.href
	)
	const json = await response.json()

	data.success = false
	data.msg = json.msg

	successMessage(data)
}

/**
 * Get a message from the backend
 * !!! if you set shortDescriptionOnly=false you have to call initErrorMessageEventListeners() after getMessageText(...) !!!
 *
 * @param {string} messageCode - the messageCode
 * @param {string} messageData - suffix which is appended to the Message
 * @param {string} locationIdentifier - url Where the error happens
 * @param {boolean} reduceLayout - simplifies Layout if needed
 * @param {boolean} shortDescriptionOnly - simplify message if needed
 * @returns {Promise<object>} promise of fetch request
 */
export function getMessageText(
	messageCode,
	messageData = '',
	locationIdentifier = '',
	reduceLayout = false,
	shortDescriptionOnly = false
) {
	return fetch(
		`/app/messages/?messageCode=${messageCode}&messageData=${messageData}&reduceLayout=${reduceLayout}&shortDescriptionOnly=${shortDescriptionOnly}&locationIdentifier=${locationIdentifier}`
	)
}

/**
 * get extended error message containing browser information and parsed user agent
 * by whatIsMyBrowserApi
 *
 * @param {string} languageLocationString - string containing information about meta-data
 * @param {string} launchInstanceID - string containing information about meta-data
 * @param {string} wsgiSchema - string containing information about meta-data
 * @param {string} userAgent - string containing information about meta-data
 * @param {string} messageCode - ID of the collapsing div
 * @param {boolean} reduceLayout - use reduced layout (usually message inside of alert)
 */
async function getExtendedErrorMessage(
	languageLocationString,
	launchInstanceID,
	wsgiSchema,
	userAgent,
	messageCode,
	reduceLayout
) {
	const button = document.querySelector(`#${messageCode}_Button`)
	const errorButtonPressed = button.innerHTML.includes('schließen')
	const firstClick = document.querySelector(
		`#${messageCode}_FirstClickIdentifier`
	).innerHTML
	if (errorButtonPressed === false && firstClick === 'isFirstClick') {
		const url = `/app/api/extendedErrorMessage/?languageLocationString=${languageLocationString}&launchInstanceID=${launchInstanceID}&wsgiSchema=${wsgiSchema}&userAgent=${userAgent}&reduceLayout=${reduceLayout}`
		const response = await fetch(url)
		const json = await response.json()
		toggleErrorMessage(messageCode, json, false)
		document.querySelector(`#${messageCode}_FirstClickIdentifier`).innerHTML =
			'alreadyClicked'
	} else {
		toggleErrorMessage(messageCode, null, false)
	}
}

/**
 * Toggles error details
 *
 * @param {string} messageCode - ID of the collapsing div
 * @param {object} data - the dictionary contaning the response
 * @param {boolean} error - error y/n?
 */
function toggleErrorMessage(messageCode, data, error) {
	const button = document.querySelector(`#${messageCode}_Button`)
	const buttonText = button.innerHTML
	const wmbArea = document.querySelector(`#${messageCode}_WMBArea`)

	if (buttonText.startsWith(gettext('Fehlerdetails anzeigen'))) {
		if (buttonText.includes('<i class="far fa-angle-down"></i>')) {
			button.innerHTML = `${gettext(
				'Fehlerdetails schließen'
			)} <i class="far fa-angle-up"></i>`
		} else {
			button.innerHTML = gettext('Fehlerdetails schließen')
		}
	} else {
		if (buttonText.includes('<i class="far fa-angle-up"></i>')) {
			button.innerHTML = `${gettext(
				'Fehlerdetails anzeigen'
			)} <i class="far fa-angle-down"></i>`
		} else {
			button.innerHTML = gettext('Fehlerdetails anzeigen')
		}
	}
	if (error === false && data !== null) {
		wmbArea.innerHTML = data.msg
	} else if (error === true) {
		wmbArea.innerHTML = gettext('Keine weiteren Details verfügbar')
	}

	toggleVisibility(`#${messageCode}`)
}

/**
 * Adds the error to the mail that is submitted to us
 *
 * @param {string} messageCode - the messageCode
 */
export function addErrorDetailsInMail(messageCode) {
	messageCode = messageCode.id.split('_')[0]
	const mailButton = document.querySelector(`#${messageCode}_MailButton`)

	if (
		mailButton.getAttribute('href').includes('%%ERROR_DETAILS_PLACEHOLDER%%')
	) {
		const target = document.querySelector(`#${messageCode}`)
		let text = target.innerText

		const removableTexts = ['.err-ex-browser', '.err-ex-email', '.err-ex-copy']
		removableTexts.forEach((c) => {
			const replaceText = target.querySelectorAll(c)[0]?.innerText
			text = text.replace(replaceText, '')
		})

		const modifieTexts = [
			'.err-ex-code',
			'.err-ex-location',
			'.err-ex-description',
			'.err-ex-time',
		]
		modifieTexts.forEach((c) => {
			const replaceText = target.querySelectorAll(c)[0]?.innerText
			text = text.replace(replaceText, `%0A ${replaceText}`)
		})

		const detailsSections = ['.err-ex-systemDetails', '.err-ex-browserDetails']
		detailsSections.forEach((c) => {
			const replaceText = target.querySelectorAll(c)[0]?.innerText
			text = text.replace(replaceText, `%0A %0A ${replaceText} %0A`)
		})

		text = text.replaceAll(',', ', %0A')

		mailButton.setAttribute(
			'href',
			mailButton
				.getAttribute('href')
				.replace('%%ERROR_DETAILS_PLACEHOLDER%%', text)
		)
	}
}
