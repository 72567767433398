import { getCSSVariable } from './cssUtils'

/**
 * Read-only object containing all colors from the `colors.scss`.
 *
 * This is usefull when you need to set a color in JavaScript
 */
const Colors = {
	Gray900: getCSSVariable('--gray-900'),
	Gray800: getCSSVariable('--gray-800'),
	Gray700: getCSSVariable('--gray-700'),
	Gray500: getCSSVariable('--gray-500'),
	Gray300: getCSSVariable('--gray-300'),
	Gray000: getCSSVariable('--gray-000'),

	Primary: getCSSVariable('--primary'),
	PrimaryLighter: getCSSVariable('--primary-lighter'),
	PrimaryDarker: getCSSVariable('--primary-darker'),

	Info: getCSSVariable('--info'),
	InfoLighter: getCSSVariable('--info-lighter'),
	InfoDarker: getCSSVariable('--info-darker'),

	Warning: getCSSVariable('--bs-warning'),
	WarningLighter: getCSSVariable('--warning-lighter'),
	WarningDarker: getCSSVariable('--warning-darker'),

	Danger: getCSSVariable('--danger'),
	DangerLighter: getCSSVariable('--danger-lighter'),
	DangerDarker: getCSSVariable('--danger-darker'),

	Success: getCSSVariable('--success'),
	SuccessLighter: getCSSVariable('--success-lighter'),
	SuccessDarker: getCSSVariable('--success-darker'),
}

export default Object.freeze(Colors)

/**
 * Calculates luminences of the color and retrieves the best readable text color
 *
 * This function is a simplified version of the w3 one: https://www.w3.org/TR/WCAG21/#dfn-relative-luminance
 *
 * @param {string} rgbColor - 6 symbol hex code of an RGB color (with or without #, but short notation [#fff] or alpha channel not allowed) OR a RGB string
 * @returns {string} - Either 'white' or' black'
 */
export function getReadableTextColor(rgbColor) {
	let r
	let g
	let b
	if (rgbColor.includes('rgb(')) {
		;[r, g, b] = rgbColor
			.replace('rgb(', '')
			.replace(')', '')
			.replace(' ', '')
			.split(',')
	} else {
		;[r, g, b] = splitByChannel(rgbColor).map(parseHex)
	}
	const L = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255
	return L > 0.5 ? 'black' : 'white'
}

/**
 * Gives a text color that is readable on the given background color.
 *
 * @param {string} hex the hex code of the color
 * @returns {string} the text color that is readable on the given background color
 */
export function getReadableTextColorWithHex(hex) {
	const r = parseInt(hex.slice(1, 3), 16)
	const g = parseInt(hex.slice(3, 5), 16)
	const b = parseInt(hex.slice(5, 7), 16)
	const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255
	return luminance > 0.5 ? 'black' : 'white'
}

/**
 * Split a 6 symbol hex color into [red, green, blue]
 *
 * @param {string} rgbHexCode - 6 symbol hex code of an RGB color (with or without #, but short notation [#fff] or alpha channel not allowed)
 * @returns {Array<number>} red, green and blue channels
 */
function splitByChannel(rgbHexCode) {
	rgbHexCode = rgbHexCode.replace('#', '')
	if (rgbHexCode.length !== 6) {
		console.error(`${rgbHexCode} is not valid. Check if it is a 6 symbol code.`)
		rgbHexCode = '23985D' // use easyVerein-primary as default if rgbHexCode not set
	}
	return rgbHexCode.match(/.{1,2}/g)
}

/**
 * Converts a hex number to number
 *
 * @param {string} hex - two symbol hex number
 * @returns {number} hex converted to integer
 */
function parseHex(hex) {
	return parseInt(hex, 16)
}
